.mainLayout,
.loginLayout {
	height: 100%;
}

.loginLayout {
	display: flex;
	align-items: center;
	flex-direction: column;

	.themeSwitcher {
		position: absolute;
		display: flex;
		width: 90%;
		justify-content: flex-end;
		z-index: 10;
		padding: 2em;
	}

	.cardLayout {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.cardContainer {
	width: 100%;
	max-width: 400px;
}

.pageLayoutContainer {
	padding: 0.5em;

	.pageLayoutCard {
		height: 100%;
	}
}
.header {
	display: flex;

	.headerSpace {
		width: 100%;
		justify-content: flex-end;
	}
}

